import {SET_SIGNINDATA} from "../../shared/constants";
  
  const loginDetailState = {
    loginDetail: []
  };
  
  function setLoginDetailState(state = loginDetailState, action) {
    switch (action.type) {
            case SET_SIGNINDATA:
            console.log("SET_SIGNINDATA  ", action.payload)
            return Object.assign({}, state, {
                loginDetail: action.payload,
            });
      default:
        return state;
    }
  }
  
  export default setLoginDetailState;
  