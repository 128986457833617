import { combineReducers } from 'redux';
import patientDetailsState from '../reducers/patientDetails';
import formDetailsState from '../reducers/formDetails';
import loginDetailState from '../reducers/loginDetail';

const appReducer = combineReducers({
  patientDetailsState,
  formDetailsState,
  loginDetailState

})

const rootReducer = (state, action) => {
   
    return appReducer(state, action)
}
export default rootReducer
