import { PATIENT_DETAILS, SET_PATIENT_DETAILS,SET_VISIT_ID, SET_PROCEDURE_ID,
  LIST_PATIENTS,PATIENTS_INFO,LIST_FLOORS,PATIENTS_PROCEDURE_INFO,PATIENTS_LAST_VISITED_INFO,SET_UPLOAD} from "../../shared/constants";

const patientDetailsState = {
  patientDetail: [],
  patientInfo:[],
  floorsList:[],
  patientBasic:[],
  patientLastVisitedInfo:[],
  procedureuploadinfo:[]
};

function setPatientDetailsState(state = patientDetailsState, action) {
  switch (action.type) {
    case PATIENT_DETAILS:
      console.log("PATIENT_DETAIL", action.payload);
      return Object.assign({}, state, {
        patientDetail: action.payload,
      });
    case SET_PATIENT_DETAILS:
      console.log("SET_PATIENT_DETAILS", action.payload);
      return Object.assign({}, state, {
        patient: action.payload,
      });
    case SET_VISIT_ID:
      console.log("SET_VISIT_ID", action.payload);
      return Object.assign({}, state, {
        visitId: action.payload,
      });
    case SET_PROCEDURE_ID:
      console.log("SET_PROCEDURE_ID", action.payload);
      return Object.assign({}, state, {
        procedureId: action.payload,
      });


      case LIST_PATIENTS:
        console.log("PATIENT_DETAIL", action.payload);
        return Object.assign({}, state, {
          patientsList: action.payload,
        });
      case PATIENTS_INFO:
        console.log("PATIENT_DETAIL", action.payload);
        return Object.assign({}, state, {
          patientBasic: action.payload,
        });
      case LIST_FLOORS:
        return Object.assign({}, state, {
          floorsList: action.payload,
        });
      case PATIENTS_PROCEDURE_INFO:
        return Object.assign({}, state, {
          patientInfo: action.payload,
        });
      case PATIENTS_LAST_VISITED_INFO:
        return Object.assign({}, state, {
          patientLastVisitedInfo: action.payload,
        });
        case SET_UPLOAD:
          console.log("setupload 1", action.payload)
          return Object.assign({}, state, {
            procedureuploadinfo: action.payload,
          });
          // case SET_SIGNINDATA:
          // console.log("SET_SIGNINDATA  ", action.payload)
          // return Object.assign({}, state, {
          //   procedureuploadinfo: action.payload,
          // });
    default:
      return state;
  }
}

export default setPatientDetailsState;
